body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.editor-common {
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.editor-title-1 {
  font-weight: 600;
  line-height: 32px;
  font-size: 22px;
  text-align: center;
}

.editor-title-2 {
  font-weight: 600;
  line-height: 28px;
  font-size: 18px;
  text-align: center;
}

.editor-title-3 {
  font-weight: 600;
  line-height: 26px;
  font-size: 16px;
  text-align: center;
}

.editor-content {
  font-weight: 500;
  line-height: 24px;
  font-size: 14px;
  text-align: left;
}

.editor-etc {
  font-weight: 500;
  line-height: 20px;
  font-size: 12px;
  text-align: left;
  color: '#04070b';
  opacity: 0.54;
}

.ant-collapse-expand-icon {
  display: flex;
  align-self: center;
}
