:root {
  --primary-color: #22549e;
  --secondary-color: #3a4a66;
}

.mainContent {
  padding-top: 30px;
}

.loginContent {
  padding-top: 30px;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.title {
  color: var(--secondary-color);
  padding-left: 15px;
  font-weight: bold;
  display: inline-block;
  align-self: center;
  margin-bottom: 0;
}

.sidebar {
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #f8f8f8;
  border-width: 0 2px 0 0;
  border-style: solid;
  border-color: #eee;
}

.sideElement {
  padding-left: 10px;
}

.sidenavLink:hover {
  transition: 0.3s;
  background-color: #dddddd;
}

.sideTitle {
  padding-top: 10px;
  padding-left: 10px;
  height: 100%;
  font-weight: bold;
  color: var(--secondary-color);
}

.login-form {
  position: absolute;
  width: 360px;
  height: 240px;
  margin-left: -180px;
  margin-top: -120px;
  left: 50%;
  top: 50%;
}
.admin-Login {
  width: 360px;
}
.login-form .form-group {
  margin-bottom: 20px !important;
}

.distributeButton {
  width: 100px;
}

.divider {
  width: 100%;
  background-color: #ddd;
  height: 2px;
  margin-bottom: 20px;
}

.saveButton {
  width: 100px;
  margin-right: 45px;
}

.crudText {
  margin-right: 80px;
}

.scroll-container {
  height: 750px;
  flex: 1;
  overflow: auto;
}

.center-text {
  text-align: center;
  border: 1px solid black;
  border-radius: 5px;
}

.categoryButton {
  width: 100%;
  height: 36px;
  padding: 0 !important;
  border-radius: 0 !important;
}

.greenText {
  color: green;
}

.redText {
  color: red;
}

.blueText {
  color: #22549e;
}

.name-tag {
  display: inline-block;
  vertical-align: middle;
  width: 75px;
  margin: 0px;
  padding-top: 6px !important;
  line-height: 25px;
}

.userInfo-text {
  padding-top: 6px !important;
  line-height: 25px;
}

.table {
  table-layout: fixed;
  margin-bottom: 0px !important;
}
.table th {
  text-align: center;
}
.table tr {
  text-align: center;
}
.table td {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  vertical-align: middle !important;
}

.tableFixHead {
  border-style: solid;
  border-width: 1px 0 1px 0;
  border-color: #ccc;
}
.tableFixHead thead th {
  background-color: #fff;
  position: sticky;
  top: -1px;
  color: var(--secondary-color);
}
.tableFixHead tbody {
  padding-top: -10px !important;
}

/* 배포 버튼 */
.distribute {
  padding-bottom: 20px;
}

.body {
  width: 100vw;
  flex-grow: 1;
}

.outer {
  height: 100vh;
}

.loading {
  padding-top: 100px;
  margin: auto;
  width: fit-content;
}

.toggleWindow {
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  color: var(--primary-color);
  margin: auto;

  &:hover {
    text-decoration: underline;
  }
}

.exposureButton {
  width: 100%;
  padding: 0 !important;
}

.linkText {
  display: inline;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.checkBoxContainer {
  padding-top: 6px;
}

.react-datepicker-popper {
  z-index: 5 !important;
  &:hover {
    cursor: pointer;
  }
}

/* dashboard */
.dashboard_title {
  color: #3a4a66;
  font-weight: bold;
  display: inline-block;
  align-self: center;
  margin-bottom: 10px;
  font-size: 1.4rem;
}
.table-divider {
  width: 100%;
  background-color: #fff;
  margin-bottom: 40px;
}
.customDate .react-datepicker-wrapper {
  display: block;
}
.customBtn {
  margin-left: 10px !important;
  margin-top: 32px;
  height: 38px;
}

.chart-title-border {
  height: 1px;
  background: #f0f0f0;
  margin-left: -16px;
  margin-right: -16px;
  margin-bottom: 24px;
}
.appBarWrapper {
  padding-left: 16px;
  padding-bottom: 16px;
}
.appTextWrapper {
  padding-left: 72px;
  padding-bottom: 16px;
}
.dashboardStyle {
  margin: 16px;
  padding: 16px;
  background-color: white;
}
.appTextWrapper .appTextTitle {
  font-weight: bold;
  margin-bottom: 16px;
  font-size: 18px;
}
.appTextWrapper .appTextList {
  margin-top: 8px;
  width: 220px;
}
.appTextWrapper .appTextList span {
  float: right;
}
.unhelpfulReasonsModal {
  background-color: #efefef;
  padding: 16px;
  height: 100px;
  overflow-y: scroll;
}

@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

/* notification */
.form-group .notificationLabel {
  justify-content: left;
}
.notificationLabel {
  width: 140px;
}

.notificationInput {
  width: calc(100% - 140px);
}

.form-group .notificationInput textarea {
  width: 100%;
}

.notificationModal .modal-dialog {
  min-width: 1200px;
}
.notificationModal .modal-token {
  font-size: 10px;
  color: #3a4a66;
}
.notificationModal .modal-user {
  font-size: 10px;
  color: #22549e;
}
.notificationModal .modal-device {
  font-size: 10px;
  color: #20c997;
}

.individualInput select {
  width: 100% !important;
}
.individualInput textarea {
  width: 100% !important;
}

.userInfo-container {
  min-width: 500px;
}
.unmaskedBtn {
  position: absolute;
  top: 0;
  right: 46px;
}

.fade_in_box {
  display: inline-block;
  background: yellow;
  padding: 10px;
  color: red;
  margin-left: 120px;
  animation: fadein 3s;
  -moz-animation: fadein 3s; /* Firefox */
  -webkit-animation: fadein 3s; /* Safari and Chrome */
  -o-animation: fadein 3s; /* Opera */
}
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fadein {
  /* Firefox */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fadein {
  /* Safari and Chrome */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-o-keyframes fadein {
  /* Opera */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.text_blinker {
  animation: blinker 3s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.notificationDiseaseType .notificationCheckBox {
  display: inline-block;
}
.notificationCheckBox label {
  display: inline-block !important;
  margin-left: 8px;
  margin-right: 16px;
}

.show {
  display: block !important;
}

.imageButton {
  display: none;
  position: absolute;
  top: calc(50% - 22px);
  width: 32px;
  height: 24px;
  padding: 4px;
  font-size: 11px;
  border-radius: 4px;
  color: white;
  cursor: pointer;
}

.replace {
  left: calc(50% - 36px);
  background-color: red;
}

.convert {
  right: calc(50% - 36px);
  background-color: deepskyblue;
}

.loadingOverlay {
  position: fixed;
  z-index: 5;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
}

/* counseling Setting */
.dnaCounselingTime div {
  line-height: 40px;
}

/* table cell a */
.ant-table-cell a {
  color: var(--primary-color) !important;
}

.editableCellValueInput input {
  padding: 4px 7px;
}

.editableCellValueWrap {
  padding: 4px 8px;
  cursor: pointer;
}

.editableRow {
  height: 56px;
}

.editableRow:hover .editableCellValueWrap {
  padding: 4px 7px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}

.deleteBtn {
  display: flex;
  font-size: 18px;
  align-items: center;
  justify-content: center;
  opacity: 0.5;
}

.deleteBtn:hover {
  opacity: 1;
}

.treatmentWindow .form-check-input {
  margin-left: -21px !important;
  width: 20px !important;
  height: 20px !important;
}

.treatmentCheck .form-check-input {
  margin-left: 0px !important;
  width: 20px !important;
  height: 20px !important;
}

.diseaseTypeDropdown {
  min-width: 200px !important;
  width: 400px !important;
}

/* header */
.headerStyle {
  height: 48px;
  line-height: 48px;
  padding-right: 16px;
  position: fixed;
  z-index: 1000;
  width: 100%;
  padding-left: 8px;
}
.headerLogo {
  cursor: pointer;
  display: inline-block;
  /* height: 48px; */
}
.headerLogo img {
  height: 24px;
  margin-left: 8px;
  margin-bottom: 4px;
}
.headerText {
  display: inline-block;
  font-size: 18px;
  font-weight: bold;
  color: white;
  margin-left: 8px;
}
.headerDrop {
  color: white;
}
.headerDrop img {
  height: 24px;
  margin-right: 8px;
}
.ant-page-header-heading-title {
  font-size: 22px;
  font-weight: bold;
}

/* sider */
.siderStyle {
  overflow: auto;
  height: calc(100vh - 48px);
  position: fixed;
  left: 0;
  top: 48px;
  bottom: 0;
  background-color: white;
  z-index: 999;
}

.ant-menu-item-group-title {
  padding: 0;
  margin: 0;
}
.ant-breadcrumb-link a:hover {
  color: rgba(0, 0, 0, 0.45);
  text-decoration: none;
  cursor: inherit;
}

/* contents */
.contentWrapper {
  margin: 16px;
}
.contentStyle {
  margin: 16px;
  padding: 16px;
  min-height: calc(100vh - 178px);
  background-color: white;
}
.right-layout {
  min-width: 990px;
}
.right-layout-floud {
  min-width: 1300px;
}
.userContentStyle {
  margin: 16px;
  min-height: calc(100vh - 178px);
}
.userContentStyle .ant-layout-content {
  padding: 16px;
  background-color: white;
}
.userTitleIcon {
  font-size: 20px;
  vertical-align: baseline;
  margin-right: 8px;
}
.white-bg {
  background-color: white;
}
.contentLayout {
  margin-left: 200px;
  margin-top: 48px;
}
.searchStyle {
  width: 360px;
  margin-bottom: 16px;
  float: right;
}
.searchResult {
  line-height: 32px;
  margin-right: 20px;
  margin-bottom: 16px;
  float: right;
  font-size: 14px;
}
.excelDownContentStyle {
  margin: 16px;
  margin-bottom: 0;
  padding: 16px;
  background-color: white;
}

.treatmentContent .col-xl-12 {
  padding: 0;
}
.treatmentContent .col-xl-11 {
  padding: 0;
}
.treatmentContent .formContent {
  margin: 0;
  padding-left: 0;
}

.tagWrapper {
  color: #979797;
  border: 1px solid #c4c4c4;
  padding: 0px 5px;
  margin: 2px;
  display: inline-block;
  white-space: nowrap;
}

/* mutation dna */
.mutationTitle {
  height: 32px;
  font-size: 14px;
  float: right;
  display: inline-flex;
  align-items: center;
}

/* bootstrapCustom */
.add-button {
  margin-left: 1rem;
}

.description-message {
  margin-left: 15px;
  color: red;
}

.modal {
  white-space: pre;
}

/* userDetail */
.healthProfileTitle {
  width: 256px;
}
.healthProfileTitle h6,
.healthProfileSub h6 {
  margin-bottom: 0;
}
.healthProfileTitle .ant-divider-horizontal,
.healthProfileSub .ant-divider-horizontal {
  margin: 12px 0;
}
.healthProfileSub {
  width: calc(100% - 256px);
}
.healthProfileSub .ant-list {
  padding-left: 24px;
}

.user_userInfo .ant-input[disabled] {
  color: #212529;
}
.user_data .ant-input[disabled] {
  color: #212529;
}
.user_data .ant-form-item {
  margin-bottom: 8px;
}
.user_userInfo .ant-form-item {
  margin-bottom: 8px;
}
.user_userInfo .ant-form-item-label {
  white-space: normal;
}
.user_userInfo .maskedBtn {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #22549e;
}
.user_userInfo .userInfoBtn {
  position: absolute;
  top: 0;
  right: 0;
  width: 100px;
}
.userDetailTab .ant-tabs-tab {
  padding-top: 0;
}

/* diseaseType Certification */
.diseaseTypeCertificationTag .ant-tag {
  padding: 5px 16px;
  font-size: 14px;
  border: 1px solid #d9d9d9;
  border-radius: 2px 0 0 2px;
  margin-right: 0;
}

/* rehabilitation */
.rehabilitationList .rehabilitationTitle {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 32px;
  display: inline-block;
}
.rehabilitationList .ant-radio-group {
  width: 100%;
}
.rehabilitationList .ant-space {
  width: 100%;
}
.rehabilitationList .ant-space Button {
  float: right;
}

.rehabilitationList span.ant-radio + * {
  font-size: 18px;
}
.rehabilitationList .addContentBtn {
  margin-bottom: 16px;
  display: inline-block;
}
.rehabilitationList .rehabilitationSubTitle {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 16px;
}
.rehabilitationList .rehabilitationSubTitle span {
  font-size: 14px;
  margin-bottom: 0;
}
.rehabilitationList .rehabilitationDescription {
  margin-bottom: 32px;
}
.rehabilitationList .listBorder {
  border-top: 1px solid #d9d9d9;
  padding: 16px 0;
}
.rehabilitationList .listBorder .listTitle {
  display: inline-block;
  margin-left: 8px;
}
.rehabilitationList .listBorder img {
  border-radius: 12px;
  margin-right: 8px;
}
.rehabilitationList .listBorder button {
  float: right;
  margin-top: 20px;
}
.rehabilitationList .iconBtn {
  font-size: 18px;
  vertical-align: baseline;
}

.hide-spinner::-webkit-outer-spin-button,
.hide-spinner::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.hide-spinner {
  -moz-appearance: textfield;
}

.rssFeedDescription {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ant-btn-link span {
  text-decoration: underline;
}

.mediaMonitorListHeader .ant-form-item-label {
  width: 120px;
  white-space: break-spaces;
}

.multiRowInput {
  margin-bottom: 2px;
}