:root {
  --primary-color: #22549e;
  --secondary-color: #3a4a66;
}

.Window {
  background: rgba(0, 0, 0, 0.25);
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.windowDropdown {
  max-height: 450px;
  overflow-y: scroll;
  max-width: 400px;
  overflow-x: scroll;
}

.formContent {
  padding-left: 15px;
  width: 100%;
}

.windowForm {
  padding-top: 15px;
}

.form-group {
  margin-bottom: 5px !important;
}

.form-label {
  color: var(--secondary-color);
  font-weight: bold;
}

.buttons {
  padding-right: 15px;
}

.selectedTags {
  cursor: default;
  color: black;
}

.required-tag {
  font-size: 7pt;
  color: rgb(219, 53, 53);
}

.dropdownButtonSingle {
  color: #495057 !important;
  padding-left: 10px !important;
  border: 1px solid #ced4da !important;
  border-radius: 0.25rem;
}

.dropdownButton {
  color: #495057 !important;
  padding: 5px 10px !important;
  border: 1px solid #ced4da !important;
  border-radius: 0.25rem;
}

.windowLoading {
  height: calc(100vh - 56px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #ccc;
}

.overlayLoading {
  height: calc(100vh - 56px);
}

.settingButton {
  padding-left: 20px;
  margin-bottom: 0px !important;
}
.settingButton:hover {
  -webkit-filter: invert(0.4);
  filter: invert(0.4);
  cursor: pointer;
}
